import { IRegisterUserRequest } from 'src/app/core/models/api/users';
import { RegisterUserViewModel } from '../models';

export abstract class RegisterUserMapper {
  static mapForUpdate(source: RegisterUserViewModel, companies: Array<number>, roles: Array<string>): IRegisterUserRequest {
    return {
      name: source.name,
      surname: source.surname,
      email: source.email,
      phoneNumber: source.phoneNumber,
      secondaryLogo: source.secondaryLogo,
      roles: roles,
      companiesIds: companies
    } as IRegisterUserRequest;
  }
}
