import { IExternalCredential } from 'src/app/core/models/api/external-apps';
import { EditExternalAppCredentialViewModel } from '../models';

export abstract class EditExternalAppCredentialMapper {
  static mapForEdit(source: IExternalCredential, target: EditExternalAppCredentialViewModel) {
    target.externalAppID = source.externalAppID;
    target.userID = source.userID;
    target.externalUserName = source.externalUserName;
    target.password = source.password;
    target.externalUserID = source.externalUserID;
    target.token = source.token;
    target.defaultCredential = source.defaultCredential === true;
    target.status = source.active === true;
  }

  static mapForUpdate(source: EditExternalAppCredentialViewModel): IExternalCredential {
    return {
      externalAppID: source.externalAppID,
      userID: source.userID,
      externalUserName: source.externalUserName,
      password: source.password,
      externalUserID: source.externalUserID,
      token: source.token,
      defaultCredential: source.defaultCredential === true,
      active: source.status === true
    } as IExternalCredential;
  }
}
