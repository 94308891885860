import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';
import { ICompany } from 'src/app/core/models/api/companies';
import { IUserCompany } from 'src/app/core/models/api/users';
import { UserEmailValidator } from 'src/app/shared/validators';
import { IRoleViewModel } from './role.viewmodel';

export class EditUserViewModel {
  private _form: FormGroup;
  private _companies: Array<ICompany> = [];
  private _roles: Array<IRoleViewModel> = [];

  readonly C_ID = 'id';
  readonly C_USERNAME = 'username';
  readonly C_NAME = 'name';
  readonly C_SURNAME = 'surname';
  readonly C_EMAIL = 'email';
  readonly C_PHONE_NUMBER = 'phoneNumber';
  readonly C_SECONDARY_LOGO = 'secondaryLogo';
  readonly C_STATUS = 'status';
  readonly C_NEW_ROLE = 'newRole';
  readonly C_NEW_COMPANY = 'newCompany';

  constructor(private userEmailVal: UserEmailValidator) {
    this._form = new FormGroup({
      [this.C_ID]: new FormControl(null, [Validators.required]),
      [this.C_USERNAME]: new FormControl(null, []),
      [this.C_NAME]: new FormControl(null, [Validators.required]),
      [this.C_SURNAME]: new FormControl(null, [Validators.required]),
      [this.C_EMAIL]: new FormControl(null, []),
      [this.C_PHONE_NUMBER]: new FormControl(null, []),
      [this.C_SECONDARY_LOGO]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(null, []),
      [this.C_NEW_COMPANY]: new FormControl(null, []),
      [this.C_NEW_ROLE]: new FormControl(null, [])
    });
  }

  userCompanies: Array<IUserCompany> = [];
  userCompaniesColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'companyName',
      fieldHeader: $localize`:@@app.edit.user.companies.name.column:Empresa`,
      canSort: true
    }
  ];
  userCompaniesButtons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.WARN,
    tooltip: $localize`:@@app.edit.user.companies.delete.tooltip:Borrar`
  }];

  userRoles: Array<IRoleViewModel> = [];
  rolesColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'rolName',
      fieldHeader: $localize`:@@app.edit.user.roles.name.column:Rol`,
      canSort: true
    }
  ];
  rolesButtons: Array<ITableButtonConfig> = [{
    name: 'delete',
    icon: 'delete',
    color: TableButtonColors.WARN,
    tooltip: $localize`:@@app.edit.user.roles.remove.tooltip:Borrar`
  }];

  get companies(): Array<ICompany> {
    return this._companies;
  }

  set companies(value: Array<ICompany>) {
    this._companies = value;
  }

  get roles(): Array<IRoleViewModel> {
    return this._roles;
  }

  set roles(value: Array<IRoleViewModel>) {
    this._roles = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  set form(value: FormGroup) {
    this._form = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._form.get(controlName)!;
  }

  get id(): number | null | undefined {
    return this.getControl(this.C_ID)?.value;
  }

  set id(value: number | null | undefined) {
    this.getControl(this.C_ID)?.setValue(value);
  }

  get userName(): string | null | undefined {
    return this.getControl(this.C_USERNAME)?.value;
  }

  set userName(value: string | null | undefined) {
    this.getControl(this.C_USERNAME)?.setValue(value);
  }

  get name(): string | null | undefined {
    return this.getControl(this.C_NAME)?.value;
  }

  set name(value: string | null | undefined) {
    this.getControl(this.C_NAME)?.setValue(value);
  }

  get surname(): string | null | undefined {
    return this.getControl(this.C_SURNAME)?.value;
  }

  set surname(value: string | null | undefined) {
    this.getControl(this.C_SURNAME)?.setValue(value);
  }

  get email(): string | null | undefined {
    return this.getControl(this.C_EMAIL)?.value;
  }

  set email(value: string | null | undefined) {
    this.getControl(this.C_EMAIL)?.setValue(value);
  }

  get phoneNumber(): string | null | undefined {
    return this.getControl(this.C_PHONE_NUMBER)?.value;
  }

  set phoneNumber(value: string | null | undefined) {
    this.getControl(this.C_PHONE_NUMBER)?.setValue(value);
  }

  get secondaryLogo(): string | null | undefined {
    return this.getControl(this.C_SECONDARY_LOGO)?.value;
  }

  set secondaryLogo(value: string | null | undefined) {
    this.getControl(this.C_SECONDARY_LOGO)?.setValue(value);
  }

  get status(): boolean | null | undefined {
    return this.getControl(this.C_STATUS)?.value;
  }

  set status(value: boolean | null | undefined) {
    this.getControl(this.C_STATUS)?.setValue(value);
  }

  get newRole(): string | null | undefined {
    return this.getControl(this.C_NEW_ROLE)?.value;
  }

  set newRole(value: string | null | undefined) {
    this.getControl(this.C_NEW_ROLE)?.setValue(value);
  }

  get newCompany(): number | null | undefined {
    return this.getControl(this.C_NEW_COMPANY)?.value;
  }

  set newCompany(value: number | null | undefined) {
    this.getControl(this.C_NEW_COMPANY)?.setValue(value);
  }
}
