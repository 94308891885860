import { IModifyUserRequest, IUser } from 'src/app/core/models/api/users';
import { EditUserViewModel } from '../models';

export abstract class EditUserMapper {
  static mapForEdit(source: IUser, target: EditUserViewModel) {
    target.id = source.id;
    target.userName = source.userName;
    target.name = source.name;
    target.surname = source.surname;
    target.email = source.email;
    target.phoneNumber = source.phoneNumber;
    target.status = source.status === 1;
  }

  static mapForUpdate(source: EditUserViewModel, companies: Array<number>, roles: Array<string>): IModifyUserRequest {
    return {
      userName: source.userName,
      name: source.name,
      surname: source.surname,
      email: source.email,
      phoneNumber: source.phoneNumber,
      secondaryLogo: source.secondaryLogo,
      roles: roles,
      companiesIds: companies,
      status: source.status !== false ? 1 : 0
    } as IModifyUserRequest;
  }
}
