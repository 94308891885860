import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, catchError, map, of } from 'rxjs';
import { IUser } from 'src/app/core/models/api/users';
import { IUsersService } from 'src/app/core/services/api/users';

@Injectable({
  providedIn: 'root'
})
export class UserEmailValidator implements AsyncValidator {
  constructor(private userSrv: IUsersService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value && control.value.length > 0) {
      return this.userSrv.get(control.value).pipe(
        map((_: IUser) => of({ notAvailable: true })),
        catchError(() => of(null))
      );
    }
    return of(null);
  }
}
