import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HowdenAskForDataService, IButtonActionData, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { tap } from 'rxjs';
import { IUser } from 'src/app/core/models/api/users';
import { IUsersService } from 'src/app/core/services/api/users';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditUserDialogComponent } from '../dialogs/edit-user-dialog/pages/edit-user-dialog.component';
import { EditUserPreferencesDialogComponent } from '../dialogs/edit-user-preferences-dialog/pages/edit-user-preferences-dialog.component';
import { RegisterUserDialogComponent } from '../dialogs/register-user-dialog/pages/register-user-dialog.component';
import { UserSearchMapper } from '../mappers';
import { UserSearchViewModel } from '../models';

@Component({
  selector: 'howden-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnDestroy {
  model = new UserSearchViewModel();

  private _subscriptions = new SubSink();

  @ViewChild('nameInput') nameInputRef!: ElementRef;

  constructor(
    private dialog: MatDialog,
    private uiBlockerSrv: IUIBlockerService,
    private askForDataSrv: HowdenAskForDataService,
    private userSrv: IUsersService
  ) {
  }

  ngOnInit(): void {
    this.loadData(true);
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.loadData();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEditUser(event);
    } else if (event.buttonName === 'preferences') {
      this.onEditUserPreferences(event);
    }
  }

  onEditUser(event: IButtonActionData): void {
    const data = {
      id: event.row.id,
      username: event.row.userName,
      email: event.row.email
    };
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      minWidth: '860px',
      width: 'auto',
      data: data
    });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(tap(() => this.loadData()))
      .subscribe();
  }

  onEditUserPreferences(event: IButtonActionData): void {
    const data = {
      id: event.row.id,
      username: event.row.userName,
      email: event.row.email
    };
    const dialogRef = this.dialog.open(EditUserPreferencesDialogComponent, {
      minWidth: '460px',
      width: 'auto',
      data: data
    });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(tap(() => this.loadData()))
      .subscribe();
  }

  onRegisterUser(): void {
    const dialogRef = this.dialog.open(RegisterUserDialogComponent, { minWidth: '860px', width: 'auto' });
    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(tap(() => this.loadData()))
      .subscribe();
  }

  private loadData(initial?: boolean): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.userSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IUser>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) => UserSearchMapper.mapForSearch(source));
      },
      complete: () => {
        this.uiBlockerSrv.unblock();

        if (initial === true) {
          this.nameInputRef.nativeElement.focus();
        }
      }
    });
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.loadData();
    });
  }
}
