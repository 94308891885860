import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, HowdenTableComponent, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';
import { IApplicationsService } from 'src/app/core/services/api/applications';
import { ICompaniesService } from 'src/app/core/services/api/companies';
import { IRolesService } from 'src/app/core/services/api/roles';
import { IUsersService } from 'src/app/core/services/api/users';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { UserEmailValidator } from 'src/app/shared/validators';
import { SubSink } from 'subsink';
import { RegisterUserMapper } from '../mappers';
import { IRoleViewModel, RegisterUserViewModel } from '../models';

@Component({
  selector: 'howden-register-user-dialog',
  templateUrl: './register-user-dialog.component.html',
  styleUrls: ['./register-user-dialog.component.scss']
})
export class RegisterUserDialogComponent implements OnInit, OnDestroy {
  model: RegisterUserViewModel;

  @ViewChild('companiesTable') companiesTable!: HowdenTableComponent;
  @ViewChild('rolesTable') rolesTable!: HowdenTableComponent;

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<RegisterUserDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private userEmailVal: UserEmailValidator,
    private appSrv: IApplicationsService,
    private companySrv: ICompaniesService,
    private roleSrv: IRolesService,
    private userSrv: IUsersService
  ) {
    this.model = new RegisterUserViewModel(this.userEmailVal);
  }

  ngOnInit(): void {
    this.uiBlockerSrv.block();

    forkJoin([
      this.appSrv.getAll(),
      this.companySrv.getAll(),
      this.roleSrv.getAll()
    ]).subscribe(([apps, companies, roles]) => {
      this.model.companies = companies;
      this.model.roles = roles.map(x => {
        const app = apps.find(a => a.appId === x.appId);
        return {
          id: x.id,
          app: app?.name,
          rolName: x.rolName,
          isAdmin: x.isAdmin
        } as IRoleViewModel;
      });

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  get disableAccept() {
    return this.model.form.invalid
      || this.companiesTable.selectedRows().length === 0
      || this.rolesTable.selectedRows().length === 0;
  }

  onAccept() {
    const companies = this.companiesTable.selectedRows();
    const roles = this.rolesTable.selectedRows();
    const request = RegisterUserMapper.mapForUpdate(this.model, companies, roles);

    this.uiBlockerSrv.block();
    this.userSrv.register(request).subscribe({
      complete: () => {
        this.uiBlockerSrv.unblock();
        this.closeDialog(DialogResultTypes.OK);
      }
    });
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
