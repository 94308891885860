<form [formGroup]="model.form" class="flex f-fd--column f-jc--center howden-dialog-content text-center">
  <div mat-dialog-title class="title-text" i18n="@@app.register.user.title">Alta de usuarios</div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <div class="f-basis--6">
        <div class="flex">
          <h2 i18n="@@app.register.user.data.header">Datos usuario</h2>
        </div>

        <div class="flex">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.register.user.email.label">Email</mat-label>
            <input matInput [formControlName]="model.C_EMAIL" [howdenControlError]="userEmailError" maxlength="256" />
            <mat-error #userEmailError></mat-error>
            @if (model.getControl(model.C_EMAIL).hasError('notAvailable')) {
              <mat-error
              i18n="@@app.register.user.email.not.available.error">El email indicado no está disponible.</mat-error>
            }
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.register.user.name.label">Nombre</mat-label>
            <input matInput [formControlName]="model.C_NAME" [howdenControlError]="userNameError" maxlength="100" />
            <mat-error #userNameError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.register.user.surname.label">Apellidos</mat-label>
            <input matInput [formControlName]="model.C_SURNAME" [howdenControlError]="userSurnameError" maxlength="250" />
            <mat-error #userSurnameError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.register.user.phone.label">Telefono</mat-label>
            <input matInput [formControlName]="model.C_PHONE_NUMBER" [howdenControlError]="userPhoneError" maxlength="100" />
            <mat-error #userPhoneError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.register.user.secondary.logo.label">Logo secundario</mat-label>
            <textarea
              matInput
              [formControlName]="model.C_SECONDARY_LOGO"
              [howdenControlError]="secondaryLogoError"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
            >
            </textarea>
            <mat-error #secondaryLogoError></mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-divider vertical="true"></mat-divider>

      <div class="f-basis--6">
        <div class="flex">
          <h2 i18n="@@app.register.user.companies.header">Empresas</h2>
        </div>

        <div class="flex">
          <howden-table
            #companiesTable
            class="small-font"
            keyField="companyID"
            sortable="local"
            [multiselect]="true"
            [cols]="model.companiesColumns"
            [data]="model.companies"
            >
          </howden-table>
        </div>

        <mat-divider></mat-divider>

        <div class="flex">
          <h2 i18n="@@app.register.user.roles.header">Roles</h2>
        </div>

        <div class="flex">
          <howden-table
            #rolesTable
            class="small-font"
            keyField="rolName"
            paginable="local"
            filterable="global-local"
            sortable="local"
            [pageSize]="5"
            [multiselect]="true"
            [cols]="model.rolesColumns"
            [data]="model.roles"
          >
          </howden-table>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>

    <button type="button" mat-raised-button color="success" [disabled]="disableAccept" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
