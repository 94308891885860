import { Injectable } from '@angular/core';
import { GENERAL_CONSTANTS, HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IModifyUserRequest, IRegisterUserRequest, ISecondaryLogo, IUser, IUserCompany, IUserSearch } from 'src/app/core/models/api/users';
import { IUserPreferences } from 'src/app/core/models/api/users/user-preferences';
import { IUsersService } from './users.contract';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements IUsersService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(email: string): Observable<IUser> {
    return this.httpClient.get<IUser>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}`);
  }

  getCompanies(email: string): Observable<Array<IUserCompany>> {
    return this.httpClient.get<Array<IUserCompany>>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/empresas`);
  }

  getRoles(email: string): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/roles`);
  }

  getSecondaryLogo(email: string): Observable<ISecondaryLogo> {
    return this.httpClient.get<ISecondaryLogo>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/secondary-logo`);
  }

  search(request: IUserSearch): Observable<IPageOf<IUser>> {
    return this.httpClient.post<IPageOf<IUser>>(GENERAL_CONSTANTS.ApiSecurityName, '/api/usuarios/search', request);
  }

  register(request: IRegisterUserRequest): Observable<void> {
    return this.httpClient.post<void>(GENERAL_CONSTANTS.ApiSecurityName, '/api/usuarios', request);
  }

  save(request: IModifyUserRequest): Observable<void> {
    return this.httpClient.put<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios`, request);
  }

  addToRole(email: string, roleName: string): Observable<void> {
    return this.httpClient.put<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/roles/${roleName}`);
  }

  removeFromRole(email: string, roleName: string): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/roles/${roleName}`);
  }

  addToCompany(email: string, companyID: number): Observable<IUserCompany> {
    return this.httpClient.put<IUserCompany>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/companies/${companyID}`);
  }

  removeFromCompany(email: string, companyID: number): Observable<void> {
    return this.httpClient.delete<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/companies/${companyID}`);
  }

  saveUserPreferences(email: string, preferences: IUserPreferences): Observable<void> {
    return this.httpClient.put<void>(GENERAL_CONSTANTS.ApiSecurityName, `/api/usuarios/${email}/preferences`, preferences);
  }
}
