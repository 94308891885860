import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResultTypes, IDialogResult } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { IExternalAppsService } from 'src/app/core/services/api/external-applications';
import { IUsersService } from 'src/app/core/services/api/users';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { SubSink } from 'subsink';
import { EditUserPreferencesMapper } from '../mappers';
import { EditUserPreferencesViewModel } from '../models';

@Component({
  selector: 'howden-edit-user-preferences-dialog',
  templateUrl: './edit-user-preferences-dialog.component.html',
  styleUrls: ['./edit-user-preferences-dialog.component.scss']
})
export class EditUserPreferencesDialogComponent implements OnInit, OnDestroy {
  model = new EditUserPreferencesViewModel();

  private _subscriptions = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) private inputData: any,
    private dialogRef: MatDialogRef<EditUserPreferencesDialogComponent>,
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private externalAppsSrv: IExternalAppsService,
    private userSrv: IUsersService
  ) {
  }

  ngOnInit(): void {
    const email = this.inputData.email;

    this.uiBlockerSrv.block();

    forkJoin([
      this.externalAppsSrv.getAll(),
      this.userSrv.get(email)
    ]).subscribe(([companies, user]) => {
      EditUserPreferencesMapper.mapForEdit(user, this.model);
      this.model.externalApps = companies;

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const title: string = $localize`: @@app.users.edit.preferences.confirm.title:Actualización de preferencias de usuario`;
    const email = this.model.email as string;
    const request = EditUserPreferencesMapper.mapForUpdate(this.model);

    this.uiBlockerSrv.block();
    this.userSrv
      .saveUserPreferences(email, request)
      .pipe(
        tap(() => {
          this._subscriptions.sink = this.confirmDialogSrv
            .openDefault(title)
            .subscribe(() => {
              this.uiBlockerSrv.unblock();
              this.closeDialog(DialogResultTypes.OK);
            });
        })
      ).subscribe();
  }

  onCancel() {
    this.closeDialog(DialogResultTypes.Cancel);
  }

  private closeDialog(result: DialogResultTypes): void {
    this.dialogRef.close({ result: result } as IDialogResult<void>);
  }
}
